import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      valid: false,
      isSaving: false,
      titleDefinition: {
        title: "",
        base64Image: "",
        fullImagePath: "",
        imageFilePath: ""
      },
      titleDefinitionId: null,
      tabIndex: 0,
      search: "",
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  created() {
    this.titleDefinitionId = this.$route.params.titleDefinitionId;
    let title =
      this.titleDefinitionId != null ? "Title definition details" : "Insert new title definition";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.titleDefinitionId != null) {
      ApiService.get(`api/promotion-title-definition`, `${this.titleDefinitionId}`)
        .then(response => {
          this.$log.debug("Title definition: ", response.data);
          this.titleDefinition = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handleImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.titleDefinition.base64Image = base64Data;
        });
      }
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveTitleDefinition(this.titleDefinition);
      }
    },
    saveTitleDefinition(titleDefinition) {
      this.isSaving = true;
      this.loading = true;
      if (titleDefinition.id != null) {
        ApiService.put(`api/promotion-title-definition`, titleDefinition)
          .then(response => {
            this.$log.debug("Title definition created: " + response);
            this.$emit("titleDefinitionSaved", "titleDefinitionSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/promotion-title-definition`, titleDefinition)
          .then(response => {
            this.$log.debug("Title definition created: " + response);
            this.$emit("titleDefinitionSaved", "titleDefinitionSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
